import { useEffect, useState, useContext } from 'react'
import { Box, Button, Title, Flex, Card, Skeleton, Grid } from '@mantine/core'
import PlansTable from '../components/Plans/Table'
import ModalPlans from '../components/Plans/Modal'
import PlanContext from '../contexts/Plans/planContext'

const Plans = () =>
{
  const planContext = useContext(PlanContext);
  const { list, loading, getPlanList, deletePlan } = planContext;
  const [ item, setItem ] = useState(null)
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() =>
  {
    (async () =>
    {
      await getPlanList();
    })()
  }, [])
  useEffect(() =>
  {
    (async () =>
    {
      await getPlanList();
    })()
  }, [ loading ])

  const onOpen = (item) =>
  {
    setIsOpen(true);
    setItem(item);
  }
  const onClose = () =>
  {
    setIsOpen(false);
    setItem(null);
  }

  return (
    <>
      <Box className='w-full gap-1 h-[100vh] py-2 px-2'>
        <Flex justify="space-between" px="md" pb="lg">
          <Title order={ 1 } >Planes</Title>
          <Button radius='md' color='yellow' autoContrast onClick={ () => onOpen(null) }>Nuevo</Button>
        </Flex>

        <div className='w-full py-1 rounded flex flex-col items-center justify-center'>
          { list !== null ?
            <PlansTable items={ list } onOpen={ onOpen } remove={ deletePlan } />
            :
            <Card shadow="sm" p="xl" radius="xl" withBorder mx="md" w="100%">
              <Grid w="100%">
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />

              </Grid>
            </Card>
          }
        </div>
      </Box>
      <ModalPlans isOpen={ isOpen } onOpen={ onOpen } onClose={ onClose } item={ item } />
    </>
  )


}

export default Plans
