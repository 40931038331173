import { useState, useEffect, useContext } from 'react'

import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar'
import { get_plans } from '../../utils/apis/plans'
import { Modal, Button, Input, Textarea, Flex, Text, Fieldset, Select } from '@mantine/core';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomerContext from '../../contexts/Customers/customerContext';
dayjs.extend(calendar)


const ModalCustomers = ({ onOpen, onClose, isOpen, item, edit }) =>
{
  const customerContext = useContext(CustomerContext);
  const { postNewCustomer, updateCustomer } = customerContext;
  const [ plans, setPlans ] = useState([]);



  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {

      data.start_from = dayjs(data.start_from).format('DD/MM/YYYY')
      data.date_billing = dayjs(data.date_billing).format('DD/MM/YYYY');
      data.limit_payday = Number(data.limit_payday);
      try {

        if (item === null) {
          await postNewCustomer(data);
          formik.resetForm()
        } else {
          data._id = item._id;
          await updateCustomer(data)
          formik.resetForm()
        }
        onClose()
      } catch (error) {
        console.log(error)
      }
    }
  })
  const close = () =>
  {
    formik.resetForm()
    onClose()
  }
  const listPlans = async () =>
  {
    const response = await get_plans()
    setPlans(response)
  }

  const changeDate = (date) =>
  {

    formik.setFieldValue('start_from', date)
    const facturacion = dayjs(date).add(1, 'month');
    formik.setFieldValue('date_billing', facturacion)

  }

  const changeTypeContract = (typeContract) =>
  {
    formik.setFieldValue('type_contract', typeContract)
    if (typeContract === 'Postpago') {
      formik.setFieldValue('limit_payday', 10);
      formik.setFieldValue('limit_date_billing', dayjs().add(1, 'M').add(10, 'day'));
    }
    if (typeContract === 'Prepago') {
      formik.setFieldValue('limit_payday', 0);
      formik.setFieldValue('limit_date_billing', dayjs().add(1, 'M').add(0, 'day'));
    }
  }


  useEffect(() =>
  {
    listPlans()
    if (item) {
      console.log(item)
      formik.setFieldValue('num_account', item.num_account);
      formik.setFieldValue('full_name', item.full_name);
      formik.setFieldValue('email', item.email);
      formik.setFieldValue('phone', item.phone);
      formik.setFieldValue('cellphone', item.cellphone);
      formik.setFieldValue('latitude', item.latitude);
      formik.setFieldValue('longitude', item.longitude);
      formik.setFieldValue('address', item.address);
      formik.setFieldValue('id_type_plan', item.id_type_plan);
      formik.setFieldValue('type_contract', item.type_contract);
      formik.setFieldValue('limit_date_billing', dayjs(item.limit_date_billing));
      formik.setFieldValue('limit_payday', item.limit_payday);
      formik.setFieldValue('payment_reference', item.payment_reference);
      let start = dayjs(item.start_from);
      let billing = dayjs(item.date_billing);
      formik.setFieldValue('start_from', start);
      formik.setFieldValue('date_billing', billing);
    } else {
      formik.setFieldValue('start_from', dayjs());
      formik.setFieldValue('date_billing', dayjs().add(1, 'month'));
    }

  }, [ item ])

  return (
    <>

      <Modal size="lg" opened={ isOpen } onClose={ close } title={ !item?._id ? 'Agregar Cliente' : 'Editar Cliente' } centered>

        <Fieldset legend="Personal information" my="md">
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="# Cuenta" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="# Cuenta"
                value={ formik.values.num_account }
                onChange={ formik.handleChange("num_account") }
              />

              { formik.touched.num_account && formik.errors.num_account && <Text c="red" fz="xs">{ formik.errors.num_account }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Nombre" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Nombre"
                value={ formik.values.full_name }
                onChange={ formik.handleChange("full_name") }
              />

              { formik.touched.full_name && formik.errors.full_name && <Text c="red" fz="xs">{ formik.errors.full_name }</Text> }
            </Input.Wrapper>

          </Flex>
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Correo" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Correo"
                value={ formik.values.email }
                onChange={ formik.handleChange("email") }
              />

              { formik.touched.email && formik.errors.email && <Text c="red" fz="xs">{ formik.errors.email }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Teléfono fijo" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Teléfono fijo"
                value={ formik.values.phone }
                onChange={ formik.handleChange("phone") }
              />

              { formik.touched.phone && formik.errors.phone && <Text c="red" fz="xs">{ formik.errors.phone }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Celular" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Celular"
                value={ formik.values.cellphone }
                onChange={ formik.handleChange("cellphone") }
              />

              { formik.touched.cellphone && formik.errors.cellphone && <Text c="red" fz="xs">{ formik.errors.cellphone }</Text> }
            </Input.Wrapper>

          </Flex>
        </Fieldset>
        <Fieldset legend="Información del plan">
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Plan" w="100%">
              <Select placeholder='Selecciona un plan'
                variant="filled"
                size="lg"
                radius="md"
                value={ formik.values.id_type_plan }
                data={ plans && plans.map(item => ({
                  value: item._id,
                  label: item.name,
                })) }
                onChange={ (value) => formik.setFieldValue("id_type_plan", value) }
              />
              { formik.touched.id_type_plan && formik.errors.id_type_plan && <Text c='red' fz={ 12 }>{ formik.errors.id_type_plan }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Contrato" w="100%">
              <Select placeholder='Selecciona un tipo'
                variant="filled"
                size="lg"
                radius="md"
                value={ formik.values.type_contract }
                data={ [ "Prepago", "Postpago" ] }
                onChange={ (e) => changeTypeContract(e) }
              />
              { formik.touched.type_contract && formik.errors.type_contract && <Text c='red' fz={ 12 }>{ formik.errors.type_contract }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Fecha de inicio" w="100%">
              <DatePickerInput value={ formik.values.start_from } valueFormat="YYYY-MM-DD" onChange={ changeDate } variant="filled"
                size="lg"
                radius="md" />
              { formik.touched.type_contract && formik.errors.type_contract && <Text c='red' fz={ 12 }>{ formik.errors.type_contract }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Fecha de facturación" w="100%">
              <DatePickerInput value={ formik.values.date_billing } disabled={ true } valueFormat="YYYY-MM-DD" variant="filled"
                size="lg"
                radius="md" />
              { formik.touched.date_billing && formik.errors.date_billing && <Text c='red' fz={ 12 }>{ formik.errors.date_billing }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Limite de días" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                disabled
                placeholder="Limite de días"
                value={ formik.values.limit_payday }
                onChange={ formik.handleChange("limit_payday") }
              />

              { formik.touched.limit_payday && formik.errors.limit_payday && <Text c="red" fz="xs">{ formik.errors.limit_payday }</Text> }
            </Input.Wrapper>

            <Input.Wrapper label="Fecha de corte" w="100%">
              <DatePickerInput value={ formik.values.limit_date_billing } disabled={ true } valueFormat="YYYY-MM-DD" variant="filled"
                size="lg"
                radius="md" />
              { formik.touched.limit_date_billing && formik.errors.limit_date_billing && <Text c='red' fz={ 12 }>{ formik.errors.limit_date_billing }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">

            <Input.Wrapper label="Referencia de pago" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Referencia de pago"
                value={ formik.values.payment_reference }
                onChange={ formik.handleChange("payment_reference") }
              />

              { formik.touched.payment_reference && formik.errors.payment_reference && <Text c="red" fz="xs">{ formik.errors.payment_reference }</Text> }
            </Input.Wrapper>

          </Flex>
        </Fieldset>
        <Fieldset legend="Dirección" my="md">
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Latitud" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Latitud"
                value={ formik.values.latitude }
                onChange={ formik.handleChange("latitude") }
              />

              { formik.touched.latitude && formik.errors.latitude && <Text c="red" fz="xs">{ formik.errors.latitude }</Text> }
            </Input.Wrapper>
            <Input.Wrapper label="Longitud" w="100%">
              <Input
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Longitud"
                value={ formik.values.longitude }
                onChange={ formik.handleChange("longitude") }
              />

              { formik.touched.longitude && formik.errors.longitude && <Text c="red" fz="xs">{ formik.errors.longitude }</Text> }
            </Input.Wrapper>
          </Flex>
          <Flex justify="space-between" align="center" px="md" py="md" gap="md">
            <Input.Wrapper label="Dirección completa" w="100%">
              <Textarea
                variant="filled"
                size="lg"
                radius="md"
                placeholder="Dirección completa"
                value={ formik.values.address }
                onChange={ formik.handleChange("address") }
              />

              { formik.touched.address && formik.errors.address && <Text c="red" fz="xs">{ formik.errors.address }</Text> }
            </Input.Wrapper>

          </Flex>
        </Fieldset>
        <Flex justify="space-between" gap="md" my="lg">
          <Button fullWidth color='gray' autoContrast variant='light' onClick={ close }>
            Cerrar
          </Button>
          <Button fullWidth color="yellow" autoContrast onClick={ () => formik.handleSubmit() }> { !item?._id ? 'Agregar' : 'Actualizar' }</Button>
        </Flex>

      </Modal>
    </>
  )
}

export default ModalCustomers;

function initialValues ()
{
  return {
    num_account: '',
    full_name: '',
    email: '',
    phone: '',
    cellphone: '',
    latitude: '',
    longitude: '',
    address: '',
    id_type_plan: '',
    payment_reference: '',
    type_contract: '',
    start_from: '',
    limit_date_billing: '',
    date_billing: '',
    limit_payday: ''
  }
}

function validationSchema ()
{
  const phoneRegExp = new RegExp('[0-9]{10}');
  const emailRegExp = /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return {
    num_account: Yup.string().required('Es requerido'),
    full_name: Yup.string().required('Es requerido'),
    email: Yup.string().matches(emailRegExp, 'No parece ser un email'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    cellphone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    latitude: Yup.string(),
    longitude: Yup.string(),
    address: Yup.string().required('La direccion es requerida'),
    payment_reference: Yup.string().required('La referencia de pago es requerida'),
    id_type_plan: Yup.string().required('El plan es requerido'),
    type_contract: Yup.string().required('ID de contrato'),
    start_from: Yup.string().required('Fecha de inicio'),
    date_billing: Yup.string().required('Fecha de facturación'),
    limit_date_billing: Yup.string(),
    limit_payday: Yup.number().required('Fecha limite de pago'),
  }
}
