import React, { useContext, useEffect } from 'react'
import
{
  Box,
  Text
} from '@chakra-ui/react'
import { Input, Button, Card, Title, Flex , PasswordInput} from '@mantine/core'
import md5 from 'md5';
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/Auth/authContext';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Logo from '../../assets/logo.png'
import
{
  DASHBOARD,
  HOME
} from '../../routes/types'
import ToggleButton from '../../components/ToggleButton';

const Login = () =>
{
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const { login, autenticado } = authContext;
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData) =>
    {
      let data = {
        username: formData.username,
        password: md5(formData.password)
      }
      await login(data)
    }
  })

  useEffect(() =>
  {

    if (autenticado) {
      navigate(DASHBOARD);
    }
  }, [ autenticado, navigate ])

  return (
    <>
      <div className='w-full'>
        <Box className='flex flex-row justify-end'>
          <ToggleButton />
        </Box>
        <div className='flex flex-row items-center justify-center w-full gap-1'>
          <Card shadow="sm" p="xl" radius="xl" withBorder mx="md" maw={400}>
            <Flex direction="column" gap="xs" px="md" py="xs">
              <Box className='flex flex-col items-center justify-center'>
                <img src={ Logo } alt="Logo GC" className='w-48 bg-white rounded-full border-2' />
              </Box>
              <Box>
                <form className='w-full'>
                  <Text className='text-center text-2xl font-semibold '>ADMIN PANEL</Text>
                  <Input.Wrapper label="Usuario" w="100%">
                    <Input
                      variant="filled"
                      size="lg"
                      radius="md"
                      placeholder="Usuario"
                      value={ formik.values.username }
                      onChange={ formik.handleChange("username") }
                    />

                    { formik.touched.username && formik.errors.username && <Text c="red" fz="xs">{ formik.errors.username }</Text> }
                  </Input.Wrapper>
                  <Input.Wrapper label="Contraseña" w="100%">
                    <PasswordInput
                      variant="filled"
                      size="lg"
                      radius="md"
                      placeholder="Contraseña"
                      value={ formik.values.password }
                      onChange={ formik.handleChange("password") }
                    />

                    { formik.touched.password && formik.errors.password && <Text c="red" fz="xs">{ formik.errors.password }</Text> }
                  </Input.Wrapper>

                    <Button my="md" fullWidth variant='filled' color='yellow' onClick={ () => formik.handleSubmit() }>Iniciar sesión</Button>

                  <Box className='w-12/12 mx-8 mb-8 text-center'>
                    {/* <Link to={`/${SIGNIN}`} className='w-full lg:w-6/12  hover:text-blue-200 active:text-blue-400 py-1 px-2 rounded text-xs mx-1'>Crear cuenta</Link> */ }
                    {/* <Link to={`/${RESTORE_PASSWORD}`} className='w-full lg:w-6/12  hover:text-blue-200 active:text-blue-400 py-1 px-2 rounded text-xs  mx-1'>Recuperar contraseña</Link> */ }
                  </Box>
                </form>
              </Box>
            </Flex>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Login

function initialValues ()
{
  return {
    username: '',
    password: ''
  }
}

function validationSchema ()
{
  return {
    username: Yup.string().required(true),
    password: Yup.string()
      .min(1, 'La contraseña debe de ser Mayor o igual a 6 caracteres')
      .max(18, 'La contraseña debe de ser menor o igual a 18 caracteres')
      .required(true)
  }
}
