import React, { useState, useEffect } from 'react'
import { currencyFormat } from 'simple-currency-format';
import { Box, Divider, Flex, Title, Card, Skeleton } from '@mantine/core';
import dayjs from 'dayjs';
import BoxSummary from '../components/Dashboard/BoxSummary';
import { get_records } from '../utils/apis/records';
import { getTempTotal } from '../utils/functions/getTotal';
import { get_customers } from '../utils/apis/customers';
import { get_renovation_nexttendays } from '../utils/apis/home';
import Record from '../components/Dashboard/Record';
import RowRenovation from '../components/Dashboard/RowRenovation';
import 'dayjs/locale/es-mx'

const Dashboard = () =>
{
  const today = dayjs().format('YYYY-MM-DD')
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ itemsCustomers, setItemsCustomers ] = useState();
  const [ itemsPostpago, setItemsPostpago ] = useState();
  const [ totalPospago, setTotalPospago ] = useState();
  const [ totalPrepago, setTotalPrepago ] = useState();
  const [ itemsPrepago, setItemsPrepago ] = useState();
  const [ totalAmount, setTotalAmount ] = useState();
  const [ totalRecords, setTotalRecords ] = useState();
  const [ records, setRecords ] = useState();
  const [ renovation, setRenovation ] = useState();
  const tenDays = dayjs().add(10, 'day').format('YYYY-MM-DD');
  const [ loadingBanks, setLoadingBanks ] = useState(true)
  const [ banks, setBanks ] = useState()
  const getRecords = async (startDate, endDate) =>
  {
    const data = {
      filter: [ dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).add(1,'day').format('DD/MM/YYYY'), ]
    }
    const res = await get_records(data);
    const postpago = res.records.filter(item => item.type_contract === 'Postpago');
    const prepago = res.records.filter(item => item.type_contract === 'Prepago')
    setRecords(res.records.reverse().slice(0, 9))
    setTotalPospago(getTempTotal(postpago));
    setTotalPrepago(getTempTotal(prepago));
    setTotalAmount(getTempTotal(res.records))
    const res2 = await get_customers();
    setItemsCustomers(res2.length)
    setItemsPostpago(res2.filter(item => item.type_contract === 'Postpago').length);
    setItemsPrepago(res2.filter(item => item.type_contract === 'Prepago').length);


    const res3 = await get_renovation_nexttendays();
    const warningDate = Date.parse(today);
    const responses = res3.filter(item => Date.parse(dayjs(item.limit_date_billing).format('YYYY-MM-DD')) >= warningDate)
    responses.sort((a, b) => new Date(a.limit_date_billing) - new Date(b.limit_date_billing))
    setRenovation(responses.slice(0, 9))
    setTotalRecords(res.records)

  }

  useEffect(() =>
  {
    setStartDate(dayjs().startOf('month').format('YYYY-MM-DD'));
    setEndDate(dayjs().endOf('month').add(1, 'day').format('YYYY-MM-DD'))
    getRecords(dayjs().startOf('month'), dayjs().endOf('month'))


  }, []);
  useEffect(() =>
  {
    sumAmountByBank(totalRecords)

  }, [ totalRecords ])

  const sumAmountByBank = (addresses) =>
  {
    setLoadingBanks(true)
    if (!Array.isArray(addresses) || addresses.length === 0) {
      return [];
    }

    const bankAmountMap = addresses.reduce((accumulator, address) =>
    {
      const { type_bank_name, amount } = address;
      // Eliminar espacios en blanco adicionales y convertir a minúsculas
      const bankName = type_bank_name.trim().toLowerCase();
      if (bankName in accumulator) {
        accumulator[ bankName ] += parseFloat(amount);
      } else {
        accumulator[ bankName ] = parseFloat(amount);
      }
      return accumulator;
    }, {});

    const result = Object.entries(bankAmountMap).map(([ bank, amount ]) => ({
      bank,
      amount
    }));
    setBanks(result)
    setLoadingBanks(false)
  }

  return (
    <>
      <Box className='w-full gap-12 px-2 h-[100%]' >
        <Flex justify="flex-start" align="end" gap="xs">
          <Title order={ 1 }>Dashboard </Title>
          <Title order={ 4 }> - { dayjs(startDate).locale('es-mx').format('MMMM') }</Title>
        </Flex>
        <Box className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mb-4'>

          { itemsPostpago ? <BoxSummary title='Usuario postpago' value={ itemsPostpago } color='gray' /> : <Skeleton height={ 80 } radius="md" /> }
          { itemsPrepago ? <BoxSummary title='Usuarios prepago' value={ itemsPrepago } color='gray' /> : <Skeleton height={ 80 } radius="md" /> }
          { itemsCustomers ? <BoxSummary title='Total usuarios' value={ itemsCustomers } color='gray' /> : <Skeleton height={ 80 } radius="md" /> }

          { totalPospago ? <BoxSummary title='Total Postpago' value={ totalPospago ? `${ currencyFormat(totalPospago, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='gray' /> : <Skeleton height={ 80 } radius="md" /> }
          { totalPrepago ? <BoxSummary title='Total Prepago' value={ totalPrepago ? `${ currencyFormat(totalPrepago, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='gray' /> : <Skeleton height={ 80 } radius="md" /> }
          { totalAmount ? <BoxSummary title='Total de ingresos' value={ totalAmount ? `${ currencyFormat(totalAmount, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='gray' /> : <Skeleton height={ 80 } radius="md" /> }



        </Box>
        <Divider py="md" />
        <Box>
          <Title order={ 2 }>Bancos</Title>
          <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>
            { loadingBanks ?
              <>
                <Skeleton height={ 80 } radius="md" />
                <Skeleton height={ 80 } radius="md" />
                <Skeleton height={ 80 } radius="md" />
                <Skeleton height={ 80 } radius="md" />
                <Skeleton height={ 80 } radius="md" />
                <Skeleton height={ 80 } radius="md" />
              </>
              : (
                <>


                  { banks && banks.map((item, index) => (
                    <BoxSummary key={ index } title={ item.bank === "undefined" ? 'Sin identificar' : item.bank } value={ item.amount ? `${ currencyFormat(item.amount, 'es-MX', 'MXN', 2) } mxn` : `${ currencyFormat(0, 'es-MX', 'MXN', 2) } mxn` } color='gray' />
                  )) }

                </>
              ) }
          </section>
        </Box>
        <Box className='my-4' type="auto" >
          <Box className="grid grid-cols-1 md:grid-cols-2 py-4 gap-2">
            <Card shadow="sm" padding="xl" radius="lg" withBorder>
              <Title order={ 2 }>Ultimas renovaciones</Title>
              { records ? records.map(item => (
                <Card.Section py="xs" key={ item._id }>
                  <Record key={ item._id } item={ item } dashboard={ true } />
                </Card.Section>
              ))

                :
                <section className='flex flex-col gap-4'>
                  <Skeleton height={ 80 } radius="md" />
                  <Skeleton height={ 80 } radius="md" />
                  <Skeleton height={ 80 } radius="md" />
                  <Skeleton height={ 80 } radius="md" />
                  <Skeleton height={ 80 } radius="md" />
                  <Skeleton height={ 80 } radius="md" />
                  <Skeleton height={ 80 } radius="md" />

                </section>
              }
            </Card>
            <Card shadow="sm" padding="xl" radius="lg" withBorder>
              <Title order={ 2 }>Proximos a vencer</Title>
              { renovation ?
                renovation.map(item => (
                  <Card.Section py="xs" key={ item._id }>
                    <RowRenovation key={ item._id } item={ item } today={ today } tenDays={ tenDays } />
                  </Card.Section>
                ))

                :
                <section className='flex flex-col gap-4'>
                  <Skeleton height={ 60 } radius="md" />
                  <Skeleton height={ 60 } radius="md" />
                  <Skeleton height={ 60 } radius="md" />
                  <Skeleton height={ 60 } radius="md" />
                  <Skeleton height={ 60 } radius="md" />
                  <Skeleton height={ 60 } radius="md" />
                  <Skeleton height={ 60 } radius="md" />

                </section>
              }


            </Card>


          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
