import React from 'react'
import { HiOutlineTrash, HiOutlinePencilAlt } from "react-icons/hi";
import 'dayjs/locale/es'
import { Table, Card, Group, ActionIcon, Tooltip, Badge } from '@mantine/core'
const UsersTable = ({ items, onOpen, remove }) =>
{
  return (
    <Card shadow="sm" p="xl" radius="xl" withBorder mx="md" w="100%">
          <Table.ScrollContainer minWidth={ 500 }>
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr className=' rounded-lg '>
            <Table.Th align="left">Usuario</Table.Th>
            <Table.Th align="left">Nombre</Table.Th>
            <Table.Th align="center">Email</Table.Th>
            <Table.Th align="center">Estado</Table.Th>
            <Table.Th align="center">Role</Table.Th>

            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          { items && items.map((item) => (
            <Table.Tr className=' py-4 px-5 '
              key={ item._id }
            >
              <Table.Td px="md" py="md">
                { item.username }
              </Table.Td>
              <Table.Td px="md" py="md">
                { item.name }
              </Table.Td>
              <Table.Td align="left">{ item?.email ? item.email : 'N/A' }</Table.Td>
              <Table.Td align='left'>
                { item?.status ? <Badge color="green" autoContrast>Activo</Badge> : <Badge color="red" autoContrast>Inactivo</Badge> }
              </Table.Td>
              <Table.Td>
                { item?.role }
              </Table.Td>
              <Table.Td className='relative'>
                <Group gap="xs">
                  <Tooltip label='Editar usuario' fontSize='md'>
                    <ActionIcon color="gray" variant="outline" radius="xl" onClick={ () => onOpen(item) } aria-label='Editar usuario' >
                      <HiOutlinePencilAlt className='text-md lg:text-lg' />
                    </ActionIcon>

                  </Tooltip>
                  <Tooltip label='Borrar usuario' fontSize='md'>
                    <ActionIcon color="red" variant="outline" radius="xl" onClick={ () => remove({ _id: item._id }) } aria-label='Eliminar usuario' >
                      <HiOutlineTrash className='text-md lg:text-lg' />
                    </ActionIcon>

                  </Tooltip>

                </Group>
              </Table.Td>
            </Table.Tr>
          )) }
        </Table.Tbody>
      </Table>
      </Table.ScrollContainer>
    </Card>
  )
}

export default UsersTable
