import React, { useState, useEffect, useContext } from 'react'
import { Modal, Button, Input, Textarea, Flex, Text } from '@mantine/core';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PlanContext from '../../contexts/Plans/planContext';



const ModalPlans = ({ onOpen, onClose, isOpen, item }) =>
{
  const planContext = useContext(PlanContext);
  const { postNewPlan, updatePlan } = planContext;
  const [ loading, setLoading ] = useState(false)
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      setLoading(true)
      try {

        if (item === null) {
          await postNewPlan(data);
          formik.resetForm()
        } else {
          data._id = item._id;
          await updatePlan(data)
          formik.resetForm()
        }
        onClose()
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  })
  const close = () =>
  {
    formik.resetForm()
    onClose()
  }
  useEffect(() =>
  {
    if (item) {
      formik.setFieldValue('name', item.name);
      formik.setFieldValue('description', item.description);
      formik.setFieldValue('megas', item.megas);
      formik.setFieldValue('price', item.price);
    }
  }, [ item ])

  return (
    <>

      <Modal size="lg" opened={ isOpen } onClose={ close } title={ !item?._id ? 'Agregar Plan' : 'Editar Plan' } centered>

        <Input.Wrapper label="Nombre">
          <Input
            variant="filled"
            size="lg"
            radius="md"
            placeholder="Nombre"
            value={ formik.values.name }
            onChange={ formik.handleChange("name") }
          />
          { formik.touched.name && formik.errors.name && <Text c="red" fz="xs">{ formik.errors.name }</Text> }
        </Input.Wrapper>
        <Input.Wrapper label="Descripción">
          <Textarea placeholder=''
            variant='filled'
            value={ formik.values.description }
            isInvalid={ formik.touched.description && formik.errors.description }
            isRequired
            onChange={ (e) => formik.setFieldValue('description', e.target.value) }
          />
          { formik.touched.description && formik.errors.description && <Text c="red" fz="xs">{ formik.errors.description }</Text> }
        </Input.Wrapper>

        {/* If you add the size prop to `InputGroup`, it'll pass it to all its children. */ }
        <div className='flex md:flex-row gap-1 items-center justify-between'>


          <Input.Wrapper label="Megas">
            <Input
              variant="filled"
              size="lg"
              radius="md"
              placeholder="Megas"
              value={ formik.values.megas }
              onChange={ formik.handleChange("megas") }
            />
            { formik.touched.megas && formik.errors.megas && <Text  c="red" fz="xs">{ formik.errors.megas }</Text> }
          </Input.Wrapper>



          <Input.Wrapper label="Precio">
            <Input
              variant="filled"
              size="lg"
              radius="md"
              placeholder="Precio"
              value={ formik.values.price }
              onChange={ formik.handleChange("price") }
            />

            { formik.touched.price && formik.errors.price && <Text c="red" fz="xs">{ formik.errors.price }</Text> }
          </Input.Wrapper>


        </div>

        <Flex justify="space-between" gap="md" my="lg">
          <Button fullWidth color='gray' autoContrast variant='light' onClick={ close }>
            Cerrar
          </Button>
          <Button fullWidth color="yellow" autoContrast onClick={ () => formik.handleSubmit() }> { !item?._id ? 'Agregar' : 'Actualizar' }</Button>
        </Flex>

      </Modal>
    </>
  )
}

export default ModalPlans;

function initialValues ()
{
  return {
    name: '',
    description: '',
    megas: '',
    price: ''

  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required('El nombre es requerido'),
    description: Yup.string().required('Es necesaría una descripción'),
    megas: Yup.number().required('Ingresa el numero de megas'),
    price: Yup.number().required('Ingresa el precio'),
  }
}
