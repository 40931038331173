import React, { useEffect, useState, useContext } from 'react'
import
{
  Spinner
} from '@chakra-ui/react'

import
{
  Card, Text,
  Title, Box, ActionIcon, Skeleton, Grid,
  Button, Flex, Input
} from '@mantine/core'

import CustomersTable from '../components/Customers/CustomersTable'
import CustomerContext from '../contexts/Customers/customerContext'
import ModalCustomers from '../components/Customers/Modal'
import { HiOutlineSearch } from 'react-icons/hi'

const Customers = () =>
{
  const customerContext = useContext(CustomerContext);
  const { list, loading, getCustomersList, deleteCustomer } = customerContext;
  const [ searchText, setSearchText ] = useState('')
  const [ items, setItems ] = useState(null);
  const [ item, setItem ] = useState(null)
  const [ isEditable, setIsEditable ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() =>
  {
    (async () =>
    {
      await getCustomersList();
    })()
  }, [])
  useEffect(() =>
  {
    (async () =>
    {
      await getCustomersList();
    })()
  }, [ loading ]);

  useEffect(() =>
  {
    (async () =>
    {
      setItems(list)
    })()


  }, [ list ])

  const onOpen = (item, edit) =>
  {
    setIsOpen(true);
    setItem(item);
    setIsEditable(edit)
  }
  const onClose = () =>
  {
    setIsOpen(false);
    setItem(null);
  }

  const searchBd = (texto) =>
  {
    setSearchText(texto);
    const originalList = list

    const filters = searchText.split(' ')
    let resultFilter = originalList
    for (let fl of filters) {
      resultFilter = resultFilter.filter(item =>
      {
        const itemData = `${ item.full_name.toUpperCase() }  ${ item.email.toUpperCase() }  ${ item.num_account.toUpperCase() }`;
        const textData = fl.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });

    }
    setItems(resultFilter)
  }

  return (
    <>
      <Box className='w-full gap-1 pt-2 px-2'>

        <Flex direction={ { base: 'column', lg: "row" } } justify="space-between" pb="lg" >
          <Title order={ 1 } my="sm">Clientes </Title>
          <Flex direction={ { base: 'column', lg: "row" } } justify="flex-end" align="center" gap="md">
            <Flex align="center" gap="xs" w={ { base: '100%', lg: 300 } }>
              <Input size="lg" w={ { base: '100%', lg: 300 } } variant='filled' placeholder='Buscar cliente' value={ searchText } onChange={ (e) => searchBd(e.target.value) } />
              <ActionIcon size="xl" color='yellow' variant='light' radius='md' autoContrast onClick={ () => searchBd(searchText) } ><HiOutlineSearch /></ActionIcon>
            </Flex>
            <Button size='md' fullWidth radius='md' color='yellow' autoContrast onClick={ () => onOpen(null, true) }>Nuevo</Button>
          </Flex>


        </Flex>
        <Flex justify="space-between" w="100%">
          { list !== null ?
            <CustomersTable items={ items } onOpen={ onOpen } remove={ deleteCustomer } />
            :
            <Card shadow="sm" p="xl" radius="xl" withBorder mx="md"  w="100%">
              <Grid w="100%">
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />

              </Grid>
            </Card>
          }
        </Flex>
      </Box>
      <ModalCustomers isOpen={ isOpen } onOpen={ onOpen } onClose={ onClose } item={ item } edit={ isEditable } />
    </>
  )
}

export default Customers
