import React, { useContext, useEffect, useState } from 'react'
import
{
  Box,
  Divider,
  NavLink,
  Tooltip,
  Avatar,
  Text
} from "@mantine/core";
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/Auth/authContext';
import { HiOutlineDocumentReport, HiOutlineUsers, HiOutlineIdentification, HiOutlineReceiptTax } from "react-icons/hi";
import { BsInboxes } from 'react-icons/bs'
import { MdOutlineDashboard } from 'react-icons/md'
import MenuItem from './MenuItem';
const Sidebar = ({ showSidebar, show, showText, setShowText }) =>
{
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext)
  const { username } = authContext;

  return (
    <>


      <div className='flex flex-row items-center gap-2 py-2 px-4'>
        <Avatar radius="xl" />
        <Text className='font-normal text-md mx-1'>  { username ? `${ username.replaceAll('"', '') }` : null } </Text>

      </div>

      <Divider className='my-2' />

      <Box >


        <div className='flex flex-col items-stretch gap-2 px-4'>

          <MenuItem icon={ <MdOutlineDashboard className='text-md lg:text-lg' /> } path={ () => navigate('/dashboard') } text='Dashboard' active={ location.pathname === '/dashboard' } />
          <NavLink
            href="/renovation/ten-days"
            label="Vencimiento"
            leftSection={ <BsInboxes className='text-md lg:text-lg' /> }
            childrenOffset={ 28 }
          >
            <MenuItem path={ () => navigate('/renovation/ten-days') } active={ location.pathname === '/renovation/ten-days' } text='10 días' />
            <MenuItem path={ () => navigate('/renovation/thirty-days') } active={ location.pathname === '/renovation/thirty-days' } text='30 días' />
          </NavLink>
          <MenuItem icon={ <HiOutlineDocumentReport className='text-md lg:text-lg' /> } path={ () => navigate('/plans') } active={ location.pathname === '/plans' } text='Planes' />
          <MenuItem icon={ <HiOutlineReceiptTax className='text-md lg:text-lg' /> } path={ () => navigate('/reports') } active={ location.pathname === '/reports' } text='Transacciones' />
          <MenuItem icon={ <HiOutlineIdentification className='text-md lg:text-lg' /> } path={ () => navigate('/customers') } active={ location.pathname === '/customers' } text='Clientes' />
          <MenuItem icon={ <HiOutlineUsers className='text-md lg:text-lg' /> } path={ () => navigate('/users') } active={ location.pathname === '/users' } text='Usuarios' />
        </div>
      </Box >
    </>

  )
}

export default Sidebar
