import { useState, useEffect } from 'react'
import { Modal, Button, Input, Textarea, Flex, Text, Badge, Select, Grid, Box } from '@mantine/core';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs';
import { renovation_customer } from '../../utils/apis/home'
import { get_banks } from '../../utils/apis/banks'


const ModalRenovation = ({ onOpen, onClose, isOpen, item }) =>
{
  const [ loading, setLoading ] = useState(false)
  const [ banks, setBanks ] = useState([])
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      setLoading(true)
      try {
        const record = {
          _id: item._id,
          num_account: data.num_account,
          comments: data.comments,
          date: dayjs(data.date).format('DD/MM/YYYY'),
          amount: data.amount,
          number_renovation: Number(data.numero),
          type_renovation: data.type_renovation,
          type_bank_name: data.type_bank_name
        }
        await renovation_customer(record);

        onClose(true)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
  })
  const close = () =>
  {
    formik.setValues(initialValues())
    onClose(null)
  }
  useEffect(() =>
  {
    if (item) {
      getBanks()
      formik.setFieldValue('num_account', item.num_account);
      if (item.type_contract === 'Postpago') {
        formik.setFieldValue('date', dayjs(item.date_billing).format('YYYY-MM-DD'));

      } else {
        formik.setFieldValue('date', dayjs().format('YYYY-MM-DD'));
      }
      formik.setFieldValue('numero', 1);
      formik.setFieldValue('type_renovation', 'MONTH');
      formik.setFieldValue('amount', item.price_type_plan);
    }
  }, [ item ])

  const getBanks = async () =>
  {
    try {
      const res = await get_banks();
      console.log(res)
      setBanks(res)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>

      <Modal size="lg" opened={ isOpen } onClose={ close } centered title={ <Flex direction="row" gap="xs" px="md" py="xs">
        <Text fz="lg" fw="bold">Cuenta: { item?.num_account }</Text>
        <Text fz="md" fw="lighter">{ item?.type_contract }</Text>
      </Flex> }>

        <Flex align="center" gap="md" px="md" py="xs">
          <Input.Wrapper label="Numero de Cuenta" w='100%'>
            <Input
              variant="filled"
              size="lg"
              radius="md"
              placeholder="Numero de Cuenta"
              value={ formik.values.num_account }
              onChange={ formik.handleChange("num_account") }
            />
            { formik.touched.num_account && formik.errors.num_account && <Text c="red" fz="xs">{ formik.errors.num_account }</Text> }
          </Input.Wrapper>
        </Flex>
        <Flex justify="space-between" align="center" px="md" py="xs" gap="md">

          <Input.Wrapper label="Fecha de inicio de servicio" w="100%">
            <Input
              variant="filled"
              size="lg"
              radius="md"
              placeholder="fecha de inicio de servicio"
              value={ formik.values.date }
              onChange={ formik.handleChange("date") }
            />

            { formik.touched.date && formik.errors.date && <Text c="red" fz="xs">{ formik.errors.date }</Text> }
          </Input.Wrapper>
          <Input.Wrapper label="Monto" w="100%">
            <Input
              variant="filled"
              size="lg"
              radius="md"
              placeholder="Monto"
              value={ formik.values.amount }
              onChange={ formik.handleChange("amount") }
            />

            { formik.touched.amount && formik.errors.amount && <Text c="red" fz="xs">{ formik.errors.amount }</Text> }
          </Input.Wrapper>
        </Flex>

        <Box className="grid grid-cols-1 sm:grid-cols-2 place-content-center items-center place-items-center gap-2" px="md" py="xs" gap="md">
          <Input.Wrapper label="Periodo de renovación" w="100%">
            <Select placeholder='Periodo de renovación'
              variant="filled"
              size="lg"
              radius="md"
              value={ formik.values.numero }
              data={ [ "", "1", "2", "3", "6", "7", "15" ] }
              onChange={ (value) => formik.setFieldValue("numero", value) }
            />
            { formik.touched.numero && formik.errors.numero && <Text c='red' fz={ 12 }>{ formik.errors.numero }</Text> }
          </Input.Wrapper>
          <Box className='w-full grid grid-cols-1  sm:grid-cols-3 gap-1  place-items-center' mt="lg">
              <Badge fullWidth  className="w-full sm:w-auto" size='lg' color={ formik.values.type_renovation === 'DAY' ? 'yellow' : 'gray' } variant={ formik.values.type_renovation === 'DAY' ? 'filled' : 'outline' } onClick={ () => formik.setFieldValue('type_renovation', 'DAY') }>Día(s)</Badge>
              <Badge fullWidth className="w-full sm:w-auto" size='lg' color={ formik.values.type_renovation === 'MONTH' ? 'yellow' : 'gray' } variant={ formik.values.type_renovation === 'MONTH' ? 'filled' : 'outline' } onClick={ () => formik.setFieldValue('type_renovation', 'MONTH') }>Mes(es)</Badge>
              <Badge fullWidth className="w-full sm:w-auto" size='lg' color={ formik.values.type_renovation === 'YEAR' ? 'yellow' : 'gray' } variant={ formik.values.type_renovation === 'YEAR' ? 'filled' : 'outline' } onClick={ () => formik.setFieldValue('type_renovation', 'YEAR') }>Año(s)</Badge>
          </Box>
        </Box>
        <Flex justify="space-between" px="md" py="xs" gap="md">
          <Input.Wrapper label="Banco" w="100%">
            <Select placeholder='Banco'
              variant="filled"
              size="lg"
              radius="md"
              value={ formik.values.type_bank_name }
              data={ banks && banks.map(item => ({
                value: item.name,
                label: item.name,
              })) }
              onChange={ (value) => formik.setFieldValue("type_bank_name", value) }
            />
            { formik.touched.type_bank_name && formik.errors.type_bank_name && <Text c='red' fz={ 12 }>{ formik.errors.type_bank_name }</Text> }
          </Input.Wrapper>
        </Flex>
        <Flex justify="space-between" px="md" py="xs" gap="md" w="100%">
          <Input.Wrapper label="Comentarios" w="100%">
            <Textarea
              variant="filled"
              size="lg"
              radius="md"
              w="100%"
              placeholder="Comentarios"
              value={ formik.values.comments }
              onChange={ formik.handleChange("comments") }
            />

            { formik.touched.comments && formik.errors.comments && <Text c="red" fz="xs">{ formik.errors.comments }</Text> }
          </Input.Wrapper>
        </Flex>

        <Flex justify="space-between" gap="md" my="md" mx="md">
          <Button fullWidth color='gray' autoContrast variant='light' onClick={ close }>
            Cerrar
          </Button>
          <Button fullWidth color="yellow" autoContrast onClick={ () => formik.handleSubmit() }> Renovar</Button>
        </Flex>
      </Modal>
    </>
  )
}

export default ModalRenovation;

function initialValues ()
{
  return {
    num_account: '',
    comments: '',
    date: '',
    amount: '',
    numero: '',
    type_renovation: '',
    type_bank_name: ''

  }
}

function validationSchema ()
{
  return {
    num_account: Yup.string().required('El nombre es requerido'),
    comments: Yup.string(),
    date: Yup.string().required('La fecha es necesaria'),
    amount: Yup.number().required('Ingresa el numero de amount'),
    numero: Yup.number().required('Es numero de renomación'),
    type_renovation: Yup.string().required('El type_renovation'),
    type_bank_name: Yup.string().required('El banco es requerido')
  }
}
