import React, { useState } from 'react'
import { currencyFormat } from 'simple-currency-format';

import { Card, Text, ActionIcon, Grid, Divider, Badge, Box, Flex } from '@mantine/core'
import { HiChevronUp, HiChevronDown } from 'react-icons/hi'
import dayjs from 'dayjs';


const bankColors = {
  "Mercado Pago": 'light-blue',
  Bbva: 'blue',
  Santander: 'red',
  Hsbc: 'red',
  Banregio: 'orange',
  Azteca: 'green',
  Spin: 'purple'
}

const Record = ({ item, dashboard = false }) =>
{
  const [ show, setShow ] = useState(false)
  return (
    <>
      <Card shadow="md" padding="xl" radius="lg" withBorder mx="md">
        <Card.Section px="md" pt="sm">

          <Flex justify="space-between" align="center">
            <Badge color={ bankColors[ item.type_bank_name ] } fz="xs">{ item.type_bank_name }</Badge>
            <ActionIcon variant="light" color="gray" radius="md" size="lg" onClick={ () => setShow(!show) }>{ show ? <HiChevronUp /> : <HiChevronDown /> }</ActionIcon>
          </Flex>
        </Card.Section>
        <Card.Section p="md">
          <Grid>
            <Grid.Col span={ { base: 6, md: 3 } }>
              <Text>{ dayjs(item?.created_date).format('DD/MMM/YYYY') }</Text>

            </Grid.Col>
            <Grid.Col span={ { base: 6, md: 3 } }>
              <Text>{ item.type_contract }</Text>

            </Grid.Col>
            <Grid.Col span={ { base: 6, md: 3 } }> <Text>{ item.num_account }</Text> </Grid.Col>
            <Grid.Col span={ { base: 6, md: 3 } }> <Text className='text-right' fw={ 600 }>{ currencyFormat(item.amount, 'es-MX', 'MXN', 2) }</Text> </Grid.Col>
          </Grid>

        </Card.Section>
        { show ? <>
          <Divider />
          <Card.Section px="md" py="sm">
            <Text fz={ 16 } fw={ 600 } my="xs">Comentarios:</Text>
            <Text fz={ 16 }> { item.comments }</Text>
          </Card.Section>
          <Divider />
          <Card.Section px="md" py="sm">
            <Grid>
              <Grid.Col span={ { base: 12, lg: 6 } }>
                <Box>
                  <Text fz={ 16 } fw={ 600 } my="xs">Información del cliente</Text>
                  <Grid>
                    <Grid.Col span={ { base: 12, lg: 6 } }>

                      <Box className='flex flex-row gap-2'>
                        <Text fz={ 14 } fw={ 400 } my="xs">Nombre:</Text>
                        <Text fz={ 14 } fw={ 400 } my="xs"> { item.full_name }</Text>
                      </Box>
                      <Box className='flex flex-row gap-2'>
                        <Text fz={ 14 } fw={ 400 } my="xs">Correo:</Text>
                        <Text fz={ 14 } fw={ 400 } my="xs"> { item.email }</Text>
                      </Box>

                    </Grid.Col>
                    <Grid.Col span={ { base: 12, lg: 6 } }>

                      <Box className='flex flex-row gap-2'>
                        <Text fz={ 14 } fw={ 400 } my="xs">Tel:</Text>
                        <Text fz={ 14 } fw={ 400 } my="xs"> { item.phone }</Text>
                      </Box>
                      <Box className='flex flex-row gap-2'>
                        <Text fz={ 14 } fw={ 400 } my="xs">Cel:</Text>
                        <Text fz={ 14 } fw={ 400 } my="xs"> { item.cellphone }</Text>
                      </Box>

                    </Grid.Col>
                  </Grid>
                </Box>
              </Grid.Col>
              <Grid.Col span={ { base: 12, lg: 6 } }>

                <Text fz={ 16 } fw={ 600 } my="xs">Información de la cuenta</Text>
                <Box className='grid grid-cols-1 md:grid-cols-2'>
                  <Box>
                    <Box className='flex flex-row gap-2'>
                      <Text fz={ 14 } fw={ 400 } my="xs">Referencia de pago:</Text>
                      <Text fz={ 14 } fw={ 400 } my="xs"> { item.payment_reference }</Text>
                    </Box>
                  </Box>
                </Box>

              </Grid.Col>
            </Grid>

          </Card.Section>
        </>
          :
          null }


      </Card>
    </>
  )
}

export default Record
