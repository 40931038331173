import { useEffect, useState } from "react";
import { Title, Flex, Input, ActionIcon, Box, Tabs, Skeleton, Card } from '@mantine/core'
import dayjs from "dayjs";
import { HiX, HiSearch } from "react-icons/hi";
import { get_renovation_nexttendays } from '../utils/apis/home'
import RenovationTable from '../components/Home/RenovationTable'

import ModalRenovation from '../components/Home//Modal';

const Home = () =>
{
  const [ items, setItems ] = useState([]);
  const [ itemsPostpago, setItemsPostpago ] = useState([]);
  const [ itemsPrepago, setItemsPrepago ] = useState([]);
  const [ item, setItem ] = useState(null)
  const [ list, setList ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ isOpen, setIsOpen ] = useState(false)
  const today = dayjs().format('YYYY-MM-DD');
  const [ loading, setLoading ] = useState(true);
  const tenDays = dayjs().add(10, 'day').format('YYYY-MM-DD');

  const onOpen = (item) =>
  {
    setIsOpen(true);
    setItem(item);
  }
  const onClose = (refresh) =>
  {
    setIsOpen(false);
    setItem(null);
    setLoading(true)
    getInfo()
    if (refresh !== null) {
      clear();
    }
  }

  // useEffect(() =>
  // {
  //   getInfo()
  // }, [ loading ])

  useEffect(() =>
  {
    getInfo()
  }, [])
  const getInfo = async () =>
  {
    const res = await get_renovation_nexttendays();

    const warningDate = Date.parse(tenDays);
    const responses = res.filter(item => Date.parse(dayjs(item.limit_date_billing).format('YYYY-MM-DD')) <= warningDate)
    setItems(responses)
    setItemsPostpago(responses.filter(item => item.type_contract === 'Postpago'));
    setItemsPrepago(responses.filter(item => item.type_contract === 'Prepago'));
    setList(responses);
    setLoading(false)
  }
  const searchBd = (texto) =>
  {
    setSearchText(texto);
    const originalList = list;
    const filters = searchText.split(" ");
    let resultFilter = originalList;
    for (let fl of filters) {
      resultFilter = resultFilter.filter((item) =>
      {
        const itemData = `${ item.full_name.toUpperCase() } ${ item.num_account.toUpperCase() }`;
        const textData = fl.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });
    }
    setItems(resultFilter);
    setItemsPostpago(
      resultFilter.filter((item) => item.type_contract === "Postpago")
    );
    setItemsPrepago(
      resultFilter.filter((item) => item.type_contract === "Prepago")
    );
  };

  const clear = () =>
  {
    setSearchText("");
    setItems(list);
    setItemsPostpago(
      list.filter((item) => item.type_contract === "Postpago")
    );
    setItemsPrepago(
      list.filter((item) => item.type_contract === "Prepago")
    );
  }
  return (
    <>

      <Box className='w-full gap-1 pt-2 px-2'>

        <Box px="md" className='flex flex-col md:flex-row justify-between  my-3'>
          <Flex direction="column">
            <Title order={ 1 }>Resumen de cuentas </Title>
            <Title order={ 4 } fw="lighter">Proximos 10 días </Title>
          </Flex>
          <Flex justify="flex-end" align="center" gap="md">
            <Input
              variant="filled"
              size="lg"
              radius="md"
              placeholder="Buscador"
              value={ searchText }
              onChange={ (e) => setSearchText(e.target.value) }
            />
            <Box className="flex flex-row gap-2">
              { searchText !== "" && (
                <ActionIcon variant="outline" size="xl" color="gray" onClick={ () => { clear() } }>
                  <HiX className="text-xl" />
                </ActionIcon>

              ) }
              <ActionIcon
                size="xl"
                color="yellow" onClick={ () => searchBd(searchText) } >
                <HiSearch className="text-xl" />
              </ActionIcon>


            </Box>
          </Flex>


        </Box>
        <div className='py-1 rounded flex flex-col items-start mt-4'>
          { loading && (
            <>
              <Flex gap="sm">
                <Skeleton height={ 40 } width={ 120 } radius="md" />
                <Skeleton height={ 40 } width={ 120 } radius="md" />
                <Skeleton height={ 40 } width={ 120 } radius="md" />
              </Flex>
              <Card shadow="sm" p="xl" radius="lg" withBorder mx="md" w="100%" my="xs">
                { [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((item, index) => (
                  <Flex gap="sm" w="100%" my="sm">
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                  </Flex>
                ))}
              </Card>
            </>
          ) }
          { !loading && items ? <Tabs defaultValue="all" variant='pills' color='yellow' className='w-full'>
            <Tabs.List px="md" py="md">
              <Tabs.Tab value="all">Todos  { items.length }</Tabs.Tab>
              <Tabs.Tab value="postpago">Postpago { itemsPostpago.length }</Tabs.Tab>
              <Tabs.Tab value="prepago">Prepago  { itemsPrepago.length }</Tabs.Tab>
            </Tabs.List>
            <Box>
              <Tabs.Panel value="all" >
                { items !== null ?
                  <RenovationTable items={ items } today={ today } tenDays={ tenDays } openModal={ onOpen } />
                  :
                  null
                }
              </Tabs.Panel>
              <Tabs.Panel value="postpago">
                { items !== null ?
                  <RenovationTable items={ itemsPostpago } today={ today } tenDays={ tenDays } openModal={ onOpen } />
                  :
                  null
                }
              </Tabs.Panel>
              <Tabs.Panel value="prepago">
                { items !== null ?
                  <RenovationTable items={ itemsPrepago } today={ today } tenDays={ tenDays } openModal={ onOpen } />
                  :
                  null
                }
              </Tabs.Panel>
            </Box>
          </Tabs>
            :
            null
          }

        </div>
      </Box>

      <ModalRenovation isOpen={ isOpen } onOpen={ onOpen } onClose={ onClose } item={ item } />
    </>
  )
}

export default Home
