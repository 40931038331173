import React, { useState } from 'react'
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'

import { Card, Table, Box, Text, Badge, Button, ActionIcon, Flex, Grid } from '@mantine/core'

const RowHome = ({ item, renovar, today, tenDays, openModal }) =>
{
  const [ show, setShow ] = useState(false)
  const hoy = Date.parse(today);
  const downDate = Date.parse(today);
  const warningDate = Date.parse(tenDays);
  const limit_date_billing = Date.parse(dayjs(item?.limit_date_billing).format('YYYY-MM-DD'))


  return (
    <>
      <Table.Tr
        key={ item._id }
      >
        <Table.Td px="md" py="lg">
          { downDate > limit_date_billing && <Badge size="xs" color='red'>Vencido</Badge> }
          { hoy === limit_date_billing && <Badge size="xs" color='orange'>Hoy Vence</Badge> }
          { downDate < limit_date_billing && warningDate >= limit_date_billing && <Badge size="xs" color='yellow'>Vencera pronto</Badge> }
          { warningDate < limit_date_billing && <Badge size="xs" color='green'>Al corriente</Badge> }

        </Table.Td>
        <Table.Td>{ dayjs(item?.date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }</Table.Td>
        <Table.Td>

          { dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }
        </Table.Td>
        <Table.Td className=' py-4 px-5'>
          { item.num_account }
        </Table.Td>

        <Table.Td align='left'>
          <Flex justify="flex-start" align="center" gap="sm">
            <Button variant='outline' autoContrast color="yellow" onClick={ () => openModal(item) } >
              Renovar
            </Button>
            <ActionIcon size="lg" variant='filled' color="gray.7" autoContrast onClick={ () => setShow(!show) }>{ show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> }</ActionIcon>
          </Flex>
        </Table.Td>
      </Table.Tr>
      { show && <Table.Tr >
        <Table.Td colSpan={ 5 }>

          <Card withBorder radius="lg" shadow="lg">
            <Text className='mt-0 pt-0 text-sm font-semibold'>Información de la cuenta</Text>
            <Grid justify="space-between" align="center" px="md" py="md">

              <Grid.Col span={ 6 }>
                <Grid >
                  <Grid.Col span={ 6 }>
                    <Flex justify="flex-start" align='center' gap="xs">
                      <MdOutlineAccountCircle className='mr-1' />{ item?.full_name ? item.full_name : 'N/A' }
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={ 6 }>
                    <Flex justify="flex-start" align='center' gap="xs">
                      <MdOutlineMail className='mr-1' /> { item?.email ? item.email : 'N/A' }
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={ 6 }>
                    <Flex justify="flex-start" align='center' gap="xs">
                      <MdOutlinePhone className='mr-1' />{ item?.phone ? item.phone : 'N/A' }
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={ 6 }>
                    <Flex justify="flex-start" align='center' gap="xs">
                      <MdOutlinePhoneIphone className='mr-1' />{ item?.cellphone ? item.cellphone : 'N/A' }
                    </Flex></Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={ 6 }>
                <Box>
                  <div >Tipo: { item?.type_contract }</div>
                  <div >Plan: { item?.name_type_plan }</div>
                  <div >Costo: ${ item?.price_type_plan }</div>
                </Box>
              </Grid.Col>
            </Grid>
          </Card>
        </Table.Td>
      </Table.Tr> }
    </>
  )
}

export default RowHome
