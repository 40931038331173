import React from 'react'
import RowHome from './RowHome';
import { renovation_customer } from '../../utils/apis/home';
import { Table, Card } from '@mantine/core'


const RenovationTable = ({ items, today, tenDays, openModal }) =>
{

  const renovar = async (item, type_renovation, number_renovation, amount, comments) =>
  {
    const data = {
      _id: item._id,
      num_account: item.num_account,
      type_renovation,
      number_renovation,
      amount,
      comments
    }
    await renovation_customer(data);
  }



  return (

    <Card shadow="sm" p="xl" radius="xl" withBorder mx="md">
      <Table.ScrollContainer minWidth={ 500 }>
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr className=' rounded-lg '>
              <Table.Th align="center">Estado</Table.Th>
              <Table.Th align="center">Fechas de corte</Table.Th>
              <Table.Th align="center">Fechas de limite</Table.Th>
              <Table.Th># Cuenta</Table.Th>
              <Table.Th align='center'>Acciones</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody >
            { items && items.map((item) => (
              <RowHome key={ item._id } openModal={ openModal } item={ item } renovar={ renovar } today={ today } tenDays={ tenDays } />
            )) }
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Card>
  )
}

export default RenovationTable
