import { useEffect, useState, useContext } from 'react'
import {Button, Title, Flex, Box, Card, Skeleton} from '@mantine/core'
import UsersTable from '../components/Users/UsersTable'
import UserContext from '../contexts/Users/userContext'
import UserModal from '../components/Users/Modal'

const Users = () => {
  const userContext = useContext(UserContext);
  const { list, loading, getUsersList, deleteUser} = userContext;
  const [item, setItem] = useState(null)
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (async () => {
      await getUsersList();
    })()
  }, [])
  useEffect(() => {
    (async () => {
      await getUsersList();
    })()
  }, [loading])


  const onOpen = (item) => {
    setIsOpen(true);
    setItem(item);
  }
  const onClose = () => {
    setIsOpen(false);
    setItem(null);
  }

  return (
    <>
    <div className=' w-full gap-1 h-[100vh] overflow-x-auto pt-2 px-2'>
      <div className='mx-2'>
        <Flex justify="space-between" gap="md" px="md" py="md">
          <Title order={1} className='text-4xl font-semibold'>Usuarios </Title>
          <Button color='yellow' autoContrast onClick={() => onOpen(null)}>Nuevo</Button>

        </Flex>
        <Box  className='py-1 rounded flex flex-col items-center justify-center overflow-auto'>
          {list !== null ?
            <UsersTable items={list} onOpen={onOpen} remove={deleteUser} />
            :
            <Card shadow="sm" p="xl" radius="lg" withBorder mx="md" w="100%" my="xs">
                { [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((item, index) => (
                  <Flex gap="sm" w="100%" my="sm">
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                    <Skeleton height={ 40 } width={ 240 } radius="md" />
                  </Flex>
                ))}
              </Card>
          }
        </Box>
      </div>
    </div>
    <UserModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} item={item} />
  </>
  )
}

export default Users
