import React, { useState, useEffect } from 'react'
import { Text, Title, Box, ActionIcon, Skeleton, Grid, Tooltip } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates';
import { currencyFormat } from 'simple-currency-format';
import { HiSearch } from 'react-icons/hi'
import { get_records } from '../utils/apis/records';

import dayjs from 'dayjs';
import Record from '../components/Records/Record';
import { getTempTotal } from '../utils/functions/getTotal';

const Records = () =>
{
  const [ items, setItems ] = useState();
  const [ startDate, setStartDate ] = useState();
  const [ total, setTotal ] = useState(0);
  const [ totalAmount, setTotalAmount ] = useState(0);
  const [ endDate, setEndDate ] = useState();

  useEffect(() =>
  {
    setStartDate(dayjs().startOf('month'));
    setEndDate(dayjs().endOf('month'))
    getRecords(dayjs().startOf('month'), dayjs().endOf('month'))
  }, []);

  const getRecords = async (startDate, endDate) =>
  {
    const data = {
      filter: [ dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).add(1, 'day').format('DD/MM/YYYY'), ]
    }
    const res = await get_records(data);
    setItems(res.records.reverse())
    setTotal(res.total_renovacion)
    setTotalAmount(getTempTotal(res.records))
  }

  const onSearch = async () =>
  {
    await getRecords(startDate, endDate);
  }

  return (
    <Box className='w-full gap-1 h-[100vh] overflow-x-auto py-2 px-2'>
      <div className='flex flex-col md:flex-row  justify-between  my-3'>
        <Box my="sm">
          <Title order={ 1 } >Registro de ingresos</Title>
          <Title order={ 5 } fw={ 400 } className='my-2'>Total de pagos registrados: { total }</Title>
        </Box>
        <Box className='flex flex-col md:flex-row justify-between gap-2 items-stretch md:items-center'>
          <Box className='flex flex-row items-center gap-1'>
            <Text>De:</Text>
            <DatePickerInput w={ '100%' } variant='filled' size="lg" radius="md" value={ startDate } valueFormat="YYYY-MM-DD" onChange={ setStartDate } />
          </Box>
          <Box className='flex flex-row items-center gap-1'>
            <Text>A:</Text>
            <DatePickerInput w={ '100%' } variant='filled' size="lg" radius="md" value={ endDate } valueFormat="YYYY-MM-DD" onChange={ setEndDate } />
          </Box>
          <Tooltip label="Buscar">
            <ActionIcon w={ { base: '100%', md: 48 } } size="xl" color='yellow' variant='filled' radius='md' autoContrast onClick={ () => onSearch() } ><HiSearch /></ActionIcon>
          </Tooltip>
        </Box>



      </div>
      <Box className='flex flex-row justify-center md:justify-end md:items-end gap-2'>

        { items ? (<>
          <Title order={ 3 } >Total:</Title>
          <Title order={ 2 }>{ currencyFormat(totalAmount, 'es-MX', 'MXN', 2) }</Title>
          <Title order={ 3 } >MXN</Title>
        </>
        ) :
          <>
            <Skeleton height={ 48 } width={ 280 } radius="md" />
          </>
        }

      </Box>
      <Grid py="md" gap="md">
        { items ? items.map(item => (
          <Grid.Col key={ item._id }>
            <Record item={ item } />
          </Grid.Col>
        ))

          :
          <>
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />

          </>
        }
      </Grid>
    </Box>
  )
}

export default Records
