const token = localStorage.getItem('token')

export const get_banks = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/get_type_banks`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "current-access":"core_gccom",
        'Accept': 'application/json',
        "security-token":token
      },
      // body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
