import React from 'react'
import { NavLink, Tooltip } from '@mantine/core';
const MenuItem = ({ active, icon, path, text }) =>
{
  return (
    <Tooltip label={ text } position='bottom'>
      <NavLink
        variant="filled"
        autoContrast
        className="rounded-lg"
        color="yellow.6"
        active={ active }
        // href={`${item.path}`}
        label={ text }
        leftSection={icon}
        onClick={path }
      />
    </Tooltip>
  )
}

export default MenuItem
