/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import userReducer from './userReducer';
import userContext from './userContext';
import { get_users, post_user, delete_user, update_user } from '../../utils/apis/users';
import {
    LIST_USERS,
    ADD_USER,
    DELETE_USER,
    UPDATE_USER,
    ERROR_USERS
} from '../types';

const UserState = props => {
    const initialState = {
        list: null,
        loading: false,
        message: null,
        errorAuth:false
    }

    const [state, dispatch] = useReducer(userReducer, initialState);

    const getUsersList = async (data) => {
        try {
            const res = await get_users(data)
            console.log(res)
            if (res.length !== 0) {

                dispatch({
                    type: LIST_USERS,
                    payload: res
                })
            } else {

            }
            if(res.error === 'Unauthorized access'){
                dispatch({
                    type: ERROR_USERS,
                    payload: 'Error'
                })
            }
        } catch (error) {


        }


    }
    const postNewUser = async (data)=>{
        try {
            const res = await post_user(data)

            if (res.inserted_id) {

                dispatch({
                    type: ADD_USER
                })
            } else {

            }
        } catch (error) {


        }
    }

    const updateUser = async (data)=>{
        try {
            await update_user(data)
            dispatch({
                type: UPDATE_USER
            })
        } catch (error) {


        }
    }

    const deleteUser = async (data)=>{
        console.log(data)
        try {
            await delete_user(data)
            dispatch({
                type: DELETE_USER
            })
        } catch (error) {


        }
    }

    return (
        <userContext.Provider
            value={{
                list: state.list,
                loading: state.loading,
                message: state.message,
                errorAuth:state.errorAuth,
                getUsersList,
                postNewUser,
                deleteUser,
                updateUser
            }}
        >
            {
                props.children
            }
        </userContext.Provider>
    )

}


export default UserState
