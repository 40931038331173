import React from 'react'
import { ActionIcon, Tooltip ,useMantineColorScheme, Box} from '@mantine/core'
import { TbSun, TbMoon } from "react-icons/tb";


const ToggleButton = ({ showText }) => {
  const { setColorScheme, colorScheme } = useMantineColorScheme();
  return (
    <Box my="lg">
      {colorScheme === "dark" ? (
        <Tooltip label="Light mode">
          <ActionIcon
            radius="lg"
            color="gray"
            variant="transparent"
            className="mr-4"
            onClick={() => setColorScheme("light")}
          >
            <TbSun size={24} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Tooltip label="Dark mode">
          <ActionIcon
            radius="lg"
            color="black"
            variant="transparent"
            className="mr-4"
            onClick={() => setColorScheme("dark")}
          >
            <TbMoon size={24} />
          </ActionIcon>
        </Tooltip>
      )}
    </Box>

  )
}

export default ToggleButton

