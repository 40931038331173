import React, { useState } from 'react'

import { Card, Table, Tooltip, Group, Text, Box, ActionIcon, Flex } from '@mantine/core';
import { HiOutlineTrash, HiOutlinePencilAlt, HiOutlineEye } from "react-icons/hi";
import { MdOutlineAccountCircle, MdOutlineMail, MdOutlinePhone, MdOutlinePhoneIphone, MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'

const CustomerRow = ({ item, onOpen, remove }) =>
{
  const [ show, setShow ] = useState(false)
  return (
    <>
      <Table.Tr className=' py-4 px-5 '
        key={ item._id }
      >
        <Table.Td px="md" py="lg">
          { item.num_account }
        </Table.Td>
        <Table.Td align="left">{ item?.full_name ? item.full_name : 'N/A' }</Table.Td>

        <Table.Td>
          { dayjs(item?.date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }
        </Table.Td>
        <Table.Td>
          { dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }
        </Table.Td>
        <Table.Td className='relative'>

            <Flex justify="flex-end" align="center" gap="xs">
              <Tooltip label='Editar Cliente' fontSize='md'>
                <ActionIcon onClick={ () => onOpen(item, true) } variant='light' color="gray.6" size="lg" aria-label='Editar cliente' ><HiOutlinePencilAlt /></ActionIcon>
              </Tooltip>
              <Tooltip label='Borrar Cliente' fontSize='md'>
                <ActionIcon onClick={ () => remove({ _id: item._id }) } variant='light' color="gray.6" size="lg" aria-label='Borrar cliente' ><HiOutlineTrash /></ActionIcon>
              </Tooltip>
              <Tooltip label='Ver Cliente' fontSize='md'>
                <ActionIcon onClick={ () => setShow(!show) } variant='light' color="gray.6" size="lg" aria-label='Ver cliente' >{ show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> }</ActionIcon>
              </Tooltip>
            </Flex>


        </Table.Td>
      </Table.Tr>
      { show && <Table.Tr>
        <Table.Td colSpan={ 5 } px="md" py="lg">
          <Box className='grid grid-cols-2 gap-2 mb-2'>
            <Text className='mt-0 pt-0 text-sm font-semibold'>Información de la cuenta</Text>
            <Text className='mt-0 pt-0 text-sm font-light text-right'>Cliente desde: { dayjs(item?.start_from).locale('es').format('DD/MMM/YYYY').toUpperCase() }</Text>
          </Box>
          <Box>

            <Box className='flex flex-row gap-1 justify-between'>
              <Box className='grid grid-cols-2'>
                <div className='flex flex-row items-center  '><MdOutlineAccountCircle className='mr-1' />{ item?.full_name ? item.full_name : 'N/A' }</div>
                <div className='flex flex-row items-center  '><MdOutlineMail className='mr-1' /> { item?.email ? item.email : 'N/A' }</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhone className='mr-1' />{ item?.phone ? item.phone : 'N/A' }</div>
                <div className='flex flex-row items-center  '> <MdOutlinePhoneIphone className='mr-1' />{ item?.cellphone ? item.cellphone : 'N/A' }</div>
              </Box>
              <Box>
                <div className=' '>Tipo: { item?.type_contract }</div>
                <div className=' '>Plan: { item?.name_type_plan }</div>
                <div className=' '>Días Limite: { item?.limit_payday }</div>
              </Box>
            </Box>
          </Box>
        </Table.Td>
      </Table.Tr> }
    </>
  )
}

export default CustomerRow
