import React from 'react'
import { HiOutlineTrash, HiOutlinePencilAlt } from "react-icons/hi";
import
{


  Wrap,
  WrapItem,
  IconButton
} from '@chakra-ui/react'

import { Card, Group, Table, Tooltip, ActionIcon } from '@mantine/core';

const PlansTable = ({ items, onOpen, remove }) =>
{
  return (
    <Card shadow="sm" p="xl" radius="xl" withBorder mx="md" className='w-full'>
      <Card.Section>
        <Table.ScrollContainer minWidth={ 500 }>
          <Table highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Th className=' py-4'>Nombre del plan</Table.Th>
                <Table.Th className='' align="left">Descripción</Table.Th>
                <Table.Th className='' align="center">Megas</Table.Th>
                <Table.Th className='' align="right">Precio</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              { items && items.map((item) => (
                <Table.Tr
                  key={ item._id }
                >
                  <Table.Td px="md" py="lg">
                    { item.name }
                  </Table.Td>
                  <Table.Td align="left">{ item?.description ? item.description : 'N/A' }</Table.Td>
                  <Table.Td align="center">{ item?.megas ? item.megas : 'N/A' } MB</Table.Td>
                  <Table.Td >$ { item.price }</Table.Td>
                  <Table.Td className='relative'>
                    <Group gap="sm">

                      <Tooltip label='Editar plan' fontSize='md'>
                        <ActionIcon size="lg" onClick={ () => onOpen(item) } variant="subtle" color="gray"  aria-label='Editar plan' >
                          <HiOutlinePencilAlt className='text-md lg:text-lg' />
                        </ActionIcon>

                      </Tooltip>
                      <Tooltip label='Borrar Plan' fontSize='md'>
                        <ActionIcon size="lg" onClick={ () => remove({ _id: item._id }) } variant="subtle" color="red"  aria-label='Editar plan' >
                          <HiOutlineTrash className='text-md lg:text-lg' />
                        </ActionIcon>

                      </Tooltip>

                    </Group>
                  </Table.Td>
                </Table.Tr>
              )) }
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Card.Section>
    </Card>

  )
}

export default PlansTable
