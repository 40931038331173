import { currencyFormat } from 'simple-currency-format';
import dayjs from 'dayjs';
import { Card, Text, Grid, Badge, Box, Flex } from '@mantine/core'

const bankColors = {
  "Mercado Pago": 'light-blue',
  Bbva: 'blue',
  Santander: 'red',
  Hsbc: 'red',
  Banregio: 'orange',
  Azteca: 'green',
}

const Record = ({ item }) =>
{
  return (

    <Card shadow="sm" padding="xl" radius="sm" withBorder mx="md">
      <Card.Section pt="xs" px="md">
        <Flex justify="space-between">

          <Badge color={ bankColors[ item.type_bank_name ] } fz="xs">{ item.type_bank_name }</Badge>
          <Text className='block xl:hidden'>{ item.type_contract }</Text>
          <Box className='block xl:hidden'>  <Text>{ dayjs(item?.created_date).format('DD-MMM') }</Text></Box>
        </Flex>
      </Card.Section>
      <Card.Section pb="xs" px="md">
        <Grid p="xs" align="center">
          <Grid.Col span={ {base:0, lg:3} } className='hidden xl:block'>  <Text>{ dayjs(item?.created_date).format('DD-MMM') }</Text></Grid.Col>
          <Grid.Col span={ { base: 4, lg: 3 } } className='hidden xl:block'>  <Text>{ item.type_contract }</Text></Grid.Col>
          <Grid.Col span={ { base: 6, lg: 3 } }><Text>{ item.num_account }</Text></Grid.Col>
          <Grid.Col span={ { base: 6, lg: 3 } }><Text className='text-right' fw={ 600 }>{ currencyFormat(item.amount, 'es-MX', 'MXN', 2) }</Text></Grid.Col>
        </Grid>
      </Card.Section>
    </Card>
  )
}

export default Record
